var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom mb-3"
  }, [_c('div', {
    staticClass: "card-header align-items-center px-4 py-3"
  }, [_c('div', {
    staticClass: "text-left flex-grow-1"
  }), _c('div', {
    staticClass: "text-center flex-grow-1"
  }, [_c('div', {
    staticClass: "text-dark-75 font-weight-bold font-size-h5"
  }, [_vm._v(_vm._s(_vm.title.name))])]), _c('div', {
    staticClass: "text-right flex-grow-1"
  }, [_c('button', {
    staticClass: "btn btn-clean btn-sm btn-icon btn-icon-md",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_c('i', {
    staticClass: "ki ki-close icon-1x"
  })])])]), _vm._m(0), _c('div', {
    staticClass: "card-footer align-items-center"
  }, [_vm.showReloadReportChat ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mt-5"
  }, [_c('textarea', {
    staticClass: "form-control border-0 p-0 flex-grow-1",
    attrs: {
      "rows": "2",
      "readonly": true,
      "placeholder": "Connection error, reload the chat..."
    }
  }), _c('button', {
    staticClass: "btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6 ml-2",
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.reloadReportChat
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Reload")) + " "), _c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Navigation/Arrow-right.svg"
    }
  })], 1)])])]) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mt-5"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputMessageSend,
      expression: "inputMessageSend"
    }],
    staticClass: "form-control border-0 p-0 flex-grow-1",
    attrs: {
      "id": "messageInput",
      "rows": "2",
      "placeholder": "Type a message",
      "readonly": !_vm.messageIsCompleted
    },
    domProps: {
      "value": _vm.inputMessageSend
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.pushMessage.apply(null, arguments);
      },
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.inputMessageSend = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6 ml-2",
    class: {
      'disabled-btn': !_vm.messageIsCompleted
    },
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.pushMessage
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Send")) + " "), _c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Navigation/Arrow-right.svg"
    }
  })], 1)])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "scroll scroll-pull",
    staticStyle: {
      "height": "264px",
      "overflow-y": "scroll"
    },
    attrs: {
      "id": "messages",
      "data-mobile-height": "350"
    }
  }, [_c('div', {
    staticClass: "ps__rail-x",
    staticStyle: {
      "left": "0px",
      "bottom": "0px"
    }
  }, [_c('div', {
    staticClass: "ps__thumb-x",
    staticStyle: {
      "left": "0px",
      "width": "0px"
    },
    attrs: {
      "tabindex": "0"
    }
  })]), _c('div', {
    staticClass: "ps__rail-y",
    staticStyle: {
      "top": "0px",
      "height": "264px",
      "right": "-2px"
    }
  }, [_c('div', {
    staticClass: "ps__thumb-y",
    staticStyle: {
      "top": "0px",
      "height": "65px"
    },
    attrs: {
      "tabindex": "0"
    }
  })])])]);

}]

export { render, staticRenderFns }