<template>
  <div class="card card-custom mb-3">
    <!--begin::Header-->
    <div class="card-header align-items-center px-4 py-3">
      <div class="text-left flex-grow-1">
      </div>
      <div class="text-center flex-grow-1">
        <div class="text-dark-75 font-weight-bold font-size-h5">{{ title.name }}</div>
      </div>
      <div class="text-right flex-grow-1">
        <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-md" @click="closeModal">
          <i class="ki ki-close icon-1x"></i>
        </button>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body">
      <!--begin::Scroll-->
      <div id="messages" class="scroll scroll-pull" data-mobile-height="350" style="height: 264px; overflow-y: scroll;">
        <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
          <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
        </div>
        <div class="ps__rail-y" style="top: 0px; height: 264px; right: -2px;">
          <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 65px;"></div>
        </div>
      </div>
      <!--end::Scroll-->
    </div>
    <!--end::Body-->

    <!--begin::Footer-->
    <div class="card-footer align-items-center">
      <!--begin::Compose-->
      <div v-if="showReloadReportChat" class="d-flex align-items-center justify-content-between mt-5">
        <textarea class="form-control border-0 p-0 flex-grow-1" rows="2" :readonly="true"
          placeholder="Connection error, reload the chat..."></textarea>
        <button type="button" class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6 ml-2"
          style="height: 100%;" @click="reloadReportChat">
          <div style="display: flex; align-items: center;">
            {{ $t("Reload") }}
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Navigation/Arrow-right.svg" />
            </span>
          </div>
        </button>
      </div>
      <div v-else class="d-flex align-items-center justify-content-between mt-5">
        <textarea id="messageInput" v-model="inputMessageSend" class="form-control border-0 p-0 flex-grow-1" rows="2"
          placeholder="Type a message" :readonly="!messageIsCompleted" @keyup.enter="pushMessage"></textarea>
        <button type="button" class="btn btn-primary btn-md text-uppercase font-weight-bold chat-send py-2 px-6 ml-2"
          :class="{ 'disabled-btn': !messageIsCompleted }" style="height: 100%;" @click="pushMessage">
          <div style="display: flex; align-items: center;">
            {{ $t("Send") }}
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Navigation/Arrow-right.svg" />
            </span>
          </div>
        </button>
      </div>
      <!--begin::Compose-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script>
const REPORT_CHAT_WS_URL = process.env.VUE_APP_WEBSOCKET_BASE_URL;

import { mapState, mapGetters } from "vuex";
import DateService from "@/core/services/date.service";
import TokenService from "@/core/services/token.service";

export default {

  props: {
    countrySelected: {
      type: String,
      required: true
    },
    title: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      inputMessageSend: '',
      reportChatWs: null,
      DateService,
      TokenService,
      intervallWSStatus: null,
      showReloadReportChat: false,
      bufferMessage: "",
      intervallMessage: null,
      formattedTime: null,
      streamId: null,
      count: 0,
      messageIsCompleted: true,
      intervallMessageCompleted: null,
      streamIsCompleted: false,
    };
  },
  computed: {
    ...mapState("auth", ["tokens"]),
    ...mapGetters("user", ["dateFormat"]),
  },
  watch: {
    countrySelected() {
      this.closeModal();
    },
  },
  async mounted() {
    let token = TokenService.refreshTokenAndUpdateState();

    // Decode the token to check its expiry time
    if (token) {
      this.startReportChatWebsocket();
    }
  },
  beforeDestroy() {
    this.reportChatWs.close();
    if (this.intervallWSStatus) {
      clearInterval(this.intervallWSStatus);
      this.intervallWSStatus = null;
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },

    async reloadReportChat() {
      // Check WebSocket connection status
      await this.startReportChatWebsocket();
      const isWsOpen = this.reportChatWs && this.reportChatWs.readyState === WebSocket.OPEN;

      // Emit 'reload' event with WebSocket connection status
      this.$emit('reload', isWsOpen);
    },

    pushMessage() {
      if (this.inputMessageSend) {
        if (this.reportChatWs.readyState === WebSocket.OPEN) {
          this.showReloadReportChat = false;
          this.messageIsCompleted = false;
          this.reportChatWs.send(JSON.stringify({ message: this.inputMessageSend }));
          document.getElementById('messages').innerHTML += '<div class="chat-bubble sent">' + this.inputMessageSend + '<span class="message-time">' + DateService.formatDate(new Date()) + '</span></div>';
          var thinkingBubble = '<div id="thinkingBubble" class="chat-bubble received thinking">Analyzing Report...</div>';
          document.getElementById('messages').innerHTML += thinkingBubble;
          this.scrollToBottom();
        }
        else {
          this.showReloadReportChat = true;
        }
        this.inputMessageSend = ''
      }
    },

    displayTypingEffect() {
      var messageArea = document.getElementById('messages');
      var newBubble = document.getElementById('msg_' + this.streamId);
      if (!newBubble) {
        newBubble = document.createElement('div');
        newBubble.classList.add('chat-bubble', 'received');
        newBubble.id = 'msg_' + this.streamId;
        messageArea.appendChild(newBubble);
      }


      var typingSpeed = 20; // Milliseconds between each character
      var vm = this;
      function typeChar() {
        if (vm.count < vm.bufferMessage.length) {
          newBubble.textContent += vm.bufferMessage.charAt(vm.count);
          vm.count++;
          vm.scrollToBottom();
          setTimeout(typeChar, typingSpeed);
        } else {
          // After the message is fully "typed", append the timestamp
          if (!vm.intervallMessageCompleted) {
            vm.intervallMessageCompleted = setInterval(() => {
              if (vm.streamIsCompleted && vm.count == vm.bufferMessage.length) {
                newBubble.innerHTML += '<span class="message-time">' + vm.formattedTime + '</span>';
                vm.scrollToBottom();
                clearInterval(vm.intervallMessage);
                clearInterval(vm.intervallMessageCompleted);
                vm.intervallMessage = null;
                vm.intervallMessageCompleted = null;
                vm.count = 0;
                vm.bufferMessage = "";
                vm.messageIsCompleted = true;
              }
            }, 1000);
          }
        }
      }

      typeChar(); // Start typing effect
    },

    startReportChatWebsocket() {
      return new Promise((resolve, reject) => {
        this.reportChatWs = new WebSocket(`${REPORT_CHAT_WS_URL}report_chat/${this.countrySelected}/`);

        this.intervallWSStatus = setInterval(() => {
          if (this.reportChatWs.readyState === WebSocket.CLOSED) {
            clearInterval(this.intervallWSStatus);
            this.intervallWSStatus = null;
            this.showReloadReportChat = true;
          }
        }, 10000);

        this.reportChatWs.addEventListener("open", () => {
          const tokens = TokenService.getLocalTokens()
          this.reportChatWs.send(JSON.stringify({ token: tokens.access }));
          resolve(); // Resolve the Promise when the WebSocket connection is open
        });

        this.reportChatWs.addEventListener("error", () => {
          // Handle WebSocket error
          this.showReloadReportChat = true;
          reject(); // Reject the Promise when a WebSocket error occurs
        });

        this.reportChatWs.addEventListener("message", (e) => {
          const data = JSON.parse(e.data);
          if (data.type) {
            if (!('system_message' in data)) {
              if (data.type == "stream_completed") {
                this.streamIsCompleted = true;
              }
              if (data.type == "text") {
                var thinkingBubble = document.getElementById('thinkingBubble');
                if (thinkingBubble) {
                  thinkingBubble.remove();
                }
                var timestamp = parseInt(data.timestamp);
                this.formattedTime = DateService.formatDate(new Date(timestamp * 1000)); // Convert Unix timestamp to Date object
                this.streamId = data.stream_id;
                this.bufferMessage += data.message;
                this.messageIsCompleted = false;
                this.streamIsCompleted = false;
                this.displayTypingEffect();

                if (!this.intervallMessage) {
                  this.intervallMessage = setInterval(() => {
                    this.displayTypingEffect();
                  }, 3000);
                }
              }
            }
          }
        });
      });
    },


    scrollToBottom() {
      let scroll = document.getElementById("messages");
      scroll.scrollTop = scroll.scrollHeight;
    }
  }
};
</script>

<style>
.chat-bubble {
  padding: 10px 15px;
  border-radius: 20px;
  margin-bottom: 10px;
  max-width: 70%;
  word-wrap: break-word;
}

.sent {
  background-color: #007bff;
  /* Bootstrap primary color */
  color: white;
  margin-left: auto;
  /* Align to right */
  border-bottom-right-radius: 0;
  /* Makes the bubble look like it's pointing to the right */
}

.received {
  background-color: #f1f1f1;
  color: black;
  margin-right: auto;
  /* Align to left */
  border-bottom-left-radius: 0;
  /* Makes the bubble look like it's pointing to the left */
}

.thinking {
  background-color: #e9ecef;
  /* Light gray background */
  color: #6c757d;
  /* Darker text color */
  font-style: italic;
  /* Italic text to indicate processing */
}

.message-time {
  display: block;
  font-size: 0.75em;
  margin-top: 5px;
  color: #888;
  text-align: right;
}

.chat-bubble.sent .message-time {
  color: white;
  /* Change the color to white for visibility */
}

.chat-bubble.received::before {
  content: " AI Report Assistant: ";
  display: block;
  font-size: 0.75em;
  font-style: normal;
  color: #888;
  margin-bottom: 5px;
  padding-left: 15px;
  background: url('/media/svg/icons/openai.svg') no-repeat left center;
  background-size: 14px 14px;
  /* Adjust as needed */
}

.chat-bubble.sent::before {
  content: " You: ";
  display: block;
  font-size: 0.75em;
  color: #e8e8e8;
  margin-bottom: 5px;
  padding-left: 15px;
  /* Adjust as needed */
  background: url('/media/svg/icons/General/User.svg') no-repeat left center;
  background-size: 14px 14px;
  /* Adjust as needed */
}

.disabled-btn {
  opacity: 0.5;
}
</style>