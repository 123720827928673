var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.territorial_jurisdiction ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "card card-custom py-20 px-20"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('p', {
    staticClass: "text-center font-size-h1 font-weight-bolder pb-5"
  }, [_vm._v(" " + _vm._s(_vm.$t("You can see the reports of one of these countries or many others")) + " ")]), _c('p', {
    staticClass: "text-center mb-20"
  }, _vm._l(_vm.landingFlags, function (flag, i) {
    return _c('country-flag', {
      key: i,
      staticClass: "mx-4",
      attrs: {
        "country-iso": flag,
        "big": ""
      }
    });
  }), 1), _c('p', {
    staticClass: "text-center font-size-h2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("Select from the report's country from the list below")) + " ")]), _c('div', {
    staticClass: "d-flex justify-content-center mb-20"
  }, [_c('euro-select', {
    staticStyle: {
      "width": "400px"
    },
    attrs: {
      "class-name": "mb-0 p-0",
      "placeholder": _vm.$t('Country'),
      "options": _vm.countriesSelect,
      "searchable": ""
    },
    model: {
      value: _vm.territorial_jurisdiction,
      callback: function callback($$v) {
        _vm.territorial_jurisdiction = $$v;
      },
      expression: "territorial_jurisdiction"
    }
  })], 1), _c('p', {
    staticClass: "text-center mb-5 font-size-h3"
  }, [_vm._v(" " + _vm._s(_vm.$t("The right colored box in the report indicate the priority of the post:")) + " ")]), _c('p', {
    staticClass: "text-center"
  }, _vm._l(_vm.riskLevelLegend, function (rl, i) {
    return _c('span', {
      key: i,
      staticClass: "label label-inline mr-2 font-weight-bolder label-xl",
      class: rl.labelClass
    }, [_vm._v(_vm._s(rl.label))]);
  }), 0)])])])]) : _vm._e(), _vm.territorial_jurisdiction ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-body d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-2 py-2"
  }, [_vm.territorial_jurisdiction ? _c('country-flag', {
    staticClass: "mr-4",
    attrs: {
      "country-iso": _vm.territorial_jurisdiction
    }
  }) : _vm._e(), _c('h3', {
    staticClass: "font-weight-bold mb-0 mr-10"
  }, [_vm._v(_vm._s(_vm.$t('Reports')))])], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "mr-4"
  }, [_vm._v(_vm._s(_vm.$t('Choose country')))]), _c('euro-select', {
    staticStyle: {
      "width": "400px"
    },
    attrs: {
      "class-name": "mr-4 mb-0 p-0",
      "placeholder": _vm.$t('Country'),
      "options": _vm.countriesSelect,
      "searchable": ""
    },
    model: {
      value: _vm.territorial_jurisdiction,
      callback: function callback($$v) {
        _vm.territorial_jurisdiction = $$v;
      },
      expression: "territorial_jurisdiction"
    }
  })], 1)])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body pr-0"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', [_c('h3', {
    staticClass: "align-items-start flex-column"
  }, [_c('div', {
    staticClass: "card-label font-weight-bold text-dark"
  }, [_vm._v(_vm._s(_vm.$t("Report index")))]), _c('span', {
    staticClass: "text-muted mt-3 font-weight-bold font-size-sm"
  }, [_vm._v(_vm._s(_vm.$t("Choose one category from the list")))])]), _vm.isLoadingCategories ? _c('div', {
    staticClass: "spinner"
  }) : _vm._e(), _vm.items.length ? _c('v-jstree', {
    attrs: {
      "data": _vm.items,
      "size": "small",
      "whole-row": ""
    },
    on: {
      "item-click": _vm.itemClick
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_) {
        return [_.model.file == 'file' || _.model.file == 'map' ? _c('div', {
          staticClass: "font-size-xs brk-cont"
        }, [_c('i', {
          staticClass: "text-primary mdi",
          class: {
            'mdi-file': _.model.file == 'file',
            'mdi-map': _.model.file == 'map'
          },
          staticStyle: {
            "font-size": "15px"
          },
          attrs: {
            "role": "presentation"
          }
        }), _c('span', {
          staticClass: "brk"
        }, [_vm._v(_vm._s(_.model.element.name))])]) : _c('div', {
          staticClass: "font-size-xs brk-cont"
        }, [_c('i', {
          staticClass: "mdi mdi-file-lock text-danger",
          staticStyle: {
            "font-size": "15px"
          },
          attrs: {
            "role": "presentation"
          }
        }), _c('span', {
          staticClass: "brk"
        }, [_vm._v(_vm._s(_.model.element.name))])])];
      }
    }], null, false, 1409601019)
  }) : _vm._e()], 1), _c('div', {
    ref: "reportList",
    staticClass: "col-xxx w-100"
  }, [_vm.categorySelected ? _c('h2', {
    staticClass: "font-weight-bolder mr-7 mb-7 text-right"
  }, [_vm._v(" " + _vm._s(_vm.categorySelected.name) + " ")]) : _vm._e(), _vm.categorySelected && !_vm.showInfoCard && !_vm.showChat && !_vm.isPurchasable && _vm.showReport ? _c('div', {
    staticClass: "card card-custom box-none mb-3 w-100",
    attrs: {
      "c": ""
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('div', {
    staticClass: "form-inline"
  }, [_c('div', {
    staticClass: "form-group mr-2 row-width-200"
  }, [_c('label', {
    staticClass: "mr-2",
    attrs: {
      "for": "reportsCountryFilter"
    }
  }, [_vm._v(_vm._s(_vm.$t("Source Country")))]), _c('euro-select', {
    attrs: {
      "id": "reportsCountryFilter",
      "class-name": "mb-0 select-large",
      "placeholder": _vm.$t('All'),
      "multiselect-props": _vm.countriesSelectProps
    },
    on: {
      "input": _vm.getReports
    },
    model: {
      value: _vm.reportsCountryFilter,
      callback: function callback($$v) {
        _vm.reportsCountryFilter = $$v;
      },
      expression: "reportsCountryFilter"
    }
  })], 1), _c('div', {
    staticClass: "form-group mr-2 row-width-90"
  }, [_c('label', {
    staticClass: "mr-2",
    attrs: {
      "for": "reportsSourceCountFilter"
    }
  }, [_vm._v(_vm._s(_vm.$t("# of sources")))]), _c('euro-select', {
    attrs: {
      "id": "reportsSourceCountFilter",
      "class-name": "mb-0",
      "placeholder": _vm.$t('Any'),
      "multiselect-props": _vm.sourceCountSelectProps
    },
    on: {
      "input": _vm.getReports
    },
    model: {
      value: _vm.reportsSourceCountFilter,
      callback: function callback($$v) {
        _vm.reportsSourceCountFilter = $$v;
      },
      expression: "reportsSourceCountFilter"
    }
  })], 1)]), _vm.territorial_jurisdiction && _vm.actualReport.length ? _c('b-button', {
    staticClass: "font-weight-bolder",
    class: {
      'spinner spinner-light spinner-right': _vm.isDownloading
    },
    attrs: {
      "variant": "light-primary",
      "disabled": _vm.isDownloading ? true : false
    },
    on: {
      "click": function click($event) {
        return _vm.downloadPdf([_vm.categorySelected], [_vm.territorial_jurisdiction]);
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-file-pdf"
  }), _vm._v(" " + _vm._s(_vm.$t("Download")) + " ")]) : _vm._e()], 1)])]) : _vm._e(), _vm.showIntro ? _c('div', {
    staticClass: "card card-custom box-none w-100"
  }, [_c('div', {
    staticClass: "card-body px-15 py-20"
  }, [_c('div', {
    staticClass: "text-center pb-6"
  }, [_c('country-flag', {
    attrs: {
      "country-iso": _vm.territorial_jurisdiction,
      "big": ""
    }
  })], 1), _c('h3', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.countriesMap[_vm.territorial_jurisdiction].name))]), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "text-center font-size-h4 font-weight-bold mt-8 mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("In this section you can find reports for each country divided by categories,")) + " " + _vm._s(_vm.$t("select a category to start consulting the report or ask a question to the AI Assistant")) + " ")])])])]) : _vm._e(), _vm.showInfoCard ? _c('div', {
    staticClass: "card card-custom box-none w-100"
  }, [_c('div', {
    staticClass: "card-body px-15 py-20"
  }, [_c('div', {
    staticClass: "text-center pb-6"
  }, [_c('country-flag', {
    attrs: {
      "country-iso": _vm.territorial_jurisdiction,
      "big": ""
    }
  })], 1), _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "text-center font-size-h4 font-weight-bold mt-8 mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("You don't have the subcription for the selected category")) + " ")]), _vm.showReport ? _c('div', [_vm.isReportInCart ? _c('button', {
    staticClass: "btn btn-danger font-weight-bolder text-uppercase",
    on: {
      "click": function click($event) {
        return _vm.removeItem(_vm.categorySelected.id + _vm.territorial_jurisdiction);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/General/Trash.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Remove from cart")) + " ")]) : _vm.isReportPending ? _c('span', {
    staticClass: "bg-warning rounded p-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("The order is pending approval")) + " ")]) : _vm.isReportParentInCart ? _c('button', {
    staticClass: "btn btn-success font-weight-bolder text-uppercase",
    attrs: {
      "disabled": ""
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Shopping/Cart1.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Subscribe here")) + " ")]) : _c('button', {
    staticClass: "btn btn-success font-weight-bolder text-uppercase",
    on: {
      "click": _vm.addToCartReport
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Shopping/Cart1.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Subscribe here")) + " ")])]) : _c('div', [_vm.isMapInCart ? _c('button', {
    staticClass: "btn btn-danger font-weight-bolder text-uppercase",
    on: {
      "click": function click($event) {
        return _vm.removeItem(_vm.item.risk_maps.id);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/General/Trash.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Remove from cart")) + " ")]) : _vm.isMapPending ? _c('span', {
    staticClass: "bg-warning rounded p-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("The order is pending approval")) + " ")]) : _c('button', {
    staticClass: "btn btn-success font-weight-bolder text-uppercase",
    attrs: {
      "disabled": _vm.isButtonAddMapDisabled
    },
    on: {
      "click": _vm.addToCartMap
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Shopping/Cart1.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Subscribe here")) + " ")])])])])]) : _vm._e(), _vm.showChat ? _c('div', {
    staticClass: "card card-custom box-none w-100"
  }, [_c('div', {
    staticClass: "card-body px-15 py-20"
  }, [_c('div', {
    staticClass: "text-center pb-6"
  }, [_c('country-flag', {
    attrs: {
      "country-iso": _vm.territorial_jurisdiction,
      "big": ""
    }
  })], 1), _c('div', {
    staticClass: "text-center mt-6"
  }, [_c('div', {
    staticClass: "text-center font-size-h4 font-weight-bold mt-8 mb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("In this section you can find reports for each country divided by categories,")) + " " + _vm._s(_vm.$t("select a category to consult the report or ask a question to the AI Assistant")) + " ")]), _c('b-button', {
    staticClass: "font-weight-bolder",
    on: {
      "click": function click($event) {
        _vm.showChatGPT = true;
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/openai.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Ask a question to the AI assistant")))])], 1), _c('div', {
    staticClass: "mt-6"
  }, [_vm.showChatGPT ? _c('ReportChat', {
    attrs: {
      "country-selected": _vm.territorial_jurisdiction,
      "title": _vm.categorySelected
    },
    on: {
      "close": function close($event) {
        _vm.showChatGPT = false;
      },
      "reload": _vm.reloadReportChat
    }
  }) : _vm._e()], 1)])]) : _vm._e(), !_vm.showInfoCard && !_vm.showChat && !_vm.isPurchasable ? _c('div', [_vm.showReport ? _c('div', [_vm.isLoadingPosts ? _c('div', [_c('div', {
    staticClass: "text-center my-20"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Loading posts...")))])])]) : _vm._e(), _vm._l(_vm.actualReport, function (report, idx) {
    var _vm$riskLevelLegend;
    return _c('div', {
      key: idx,
      staticClass: "card card-custom box-none mb-3 w-100"
    }, [_c('div', {
      staticClass: "card-header ribbon ribbon-clip ribbon-right"
    }, [_c('div', {
      staticClass: "ribbon-target",
      staticStyle: {
        "top": "15px",
        "height": "45px"
      }
    }, [_c('span', {
      staticClass: "ribbon-inner",
      class: (_vm$riskLevelLegend = _vm.riskLevelLegend[report.stoplight - 1]) === null || _vm$riskLevelLegend === void 0 ? void 0 : _vm$riskLevelLegend.colorClass
    }), _vm._v("    ")]), _c('div', {
      staticClass: "card-title align-items-start flex-column py-3 w-100"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between w-100"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [report.ai_post ? _c('div', {
      staticClass: "mr-4",
      staticStyle: {
        "width": "25px"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": "/media/svg/icons/chatgpt.svg"
      }
    })], 1) : _vm._e(), !report.ai_post ? _c('h3', {
      staticClass: "card-label font-weight-bolder"
    }, [_vm._v(" " + _vm._s(report.title) + " ")]) : _c('h3', {
      staticClass: "card-label font-weight-bolder"
    }, [_vm._v(" " + _vm._s(report.categories[0].name) + " ")])]), _c('div', [_c('b-dropdown', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        value: _vm.$t('Show source detail to specific country'),
        expression: "$t('Show source detail to specific country')",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "no-caret": "",
        "right": "",
        "no-flip": "",
        "text": _vm.$t('Sources'),
        "toggle-class": "font-weight-bold"
      }
    }, [_c('div', {
      staticClass: "navi navi-hover"
    }, _vm._l(report.sources_body, function (source, i) {
      return _c('b-dropdown-item', {
        key: i,
        staticClass: "navi-item",
        attrs: {
          "tag": "div"
        },
        on: {
          "click": function click($event) {
            return _vm.modalReport(source, report.title);
          }
        }
      }, [_vm._v(" " + _vm._s(source.country) + " ")]);
    }), 1)])], 1)]), _c('div', {
      staticClass: "mt-1"
    }, [_c('b-badge', {
      staticClass: "align-self-start",
      staticStyle: {
        "font-size": "11px"
      },
      attrs: {
        "variant": !report.valid_to || _vm.dateDiff(report.valid_to) ? 'success' : 'danger'
      }
    }, [_c('span', [_vm._v(_vm._s(_vm.$t("Valid From")) + ": " + _vm._s(_vm.DateService.format(report.valid_from, _vm.date_format)))]), report.valid_to ? _c('span', [_vm._v(_vm._s(_vm.$t("To")) + ": " + _vm._s(_vm.DateService.format(report.valid_to, _vm.date_format)))]) : _vm._e()])], 1)])]), _c('div', {
      staticClass: "card-body nop"
    }, [_c('div', {
      staticClass: "report-body",
      domProps: {
        "innerHTML": _vm._s(report.body)
      }
    })])]);
  })], 2) : _c('div', {
    staticClass: "col-xx w-100"
  }, [_vm.riskMapEnable ? _c('div', {
    staticClass: "card card-custom box-none"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-8"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-2 py-2"
  }, [_vm.territorial_jurisdiction ? _c('country-flag', {
    attrs: {
      "country-iso": _vm.territorial_jurisdiction
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('ul', {
    staticClass: "level-legend my-0"
  }, _vm._l(_vm.riskLevelLegendMap, function (levelLegend, idx) {
    return _c('li', {
      key: idx,
      staticClass: "d-flex align-items-center"
    }, [_c('rectangle', {
      attrs: {
        "label": levelLegend.label,
        "color": levelLegend.color
      }
    }), _c('span', {
      staticClass: "mr-2 font-weight-bold"
    }, [_vm._v(_vm._s(levelLegend.text))])], 1);
  }), 0)])]), _c('google-maps-map', {
    staticClass: "border",
    attrs: {
      "height": "600px",
      "geocoder-options": _vm.gmapGeocoder,
      "kml-layers-urls": _vm.kmlUrl
    }
  }), _c('div', {
    staticClass: "pre-assessment"
  }, [_c('h2', [_vm._v(_vm._s(_vm.$t("Country Assessment")))]), _vm.showPerAssessment ? _c('div', {
    staticClass: "pre-content"
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.convertMarkdownToHtml(_vm.contentPerAssessment))
    }
  }), _c('h2', [_vm._v(_vm._s(_vm.$t("Vaccinations & Risk Categories")))]), _c('div', {
    staticClass: "row"
  }, [_vm.mandatoryVaccinations.length || _vm.recommendedVaccinations.length ? _c('div', {
    staticClass: "col"
  }, [_vm.mandatoryVaccinations.length ? _c('div', {
    staticClass: "mb-6"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Mandatory Vaccinations:")))]), _vm._l(_vm.mandatoryVaccinations, function (mandatoryVaccination) {
    return _c('div', {
      key: mandatoryVaccination
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "mb-2 badge badge-warning"
    }, [_vm._v(_vm._s(mandatoryVaccination.vaccine_name))])])]);
  })], 2) : _vm._e(), _vm.recommendedVaccinations.length ? _c('div', [_c('h3', [_vm._v(_vm._s(_vm.recommendedVaccinations.length) + " " + _vm._s(_vm.$t("Recommended Vaccinations:")))]), _vm._l(_vm.recommendedVaccinations, function (recommendedVaccination) {
    return _c('div', {
      key: recommendedVaccination
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "mb-2 badge badge-info"
    }, [_vm._v(_vm._s(recommendedVaccination.vaccine_name))])])]);
  })], 2) : _vm._e()]) : _vm._e(), _vm.categoriesOfRisks.length ? _c('div', {
    staticClass: "col"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Risk Categories:")))]), _vm._l(_vm.categoriesOfRisks, function (categorieRisk) {
    return _c('div', {
      key: categorieRisk.id
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "mb-2 badge badge-secondary"
    }, [_vm._v(_vm._s(categorieRisk.name))])])]);
  })], 2) : _vm._e()])]) : _c('div', [_c('span', {
    staticClass: "pr-4"
  }, [_vm._v(_vm._s(_vm.$t("Request pre-assessment of the country")))]), _c('button', {
    staticClass: "btn btn-success font-weight-bolder text-uppercase",
    on: {
      "click": _vm.requestPerAssessment
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/General/Binocular.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Request Pre Assessment")) + " ")])])])], 1)]) : _vm._e()])]) : _vm._e()])])])])])]) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "report-modal",
      "size": "xl",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center py-0 w-100"
        }, [_c('div', [_c('h3', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.showReportSource.country) + " " + _vm._s(_vm.$t("Source")))]), _c('h5', {
          staticClass: "my-0 font-weight-bold"
        }, [_c('span', {
          staticClass: "badge align-self-start badge-success"
        }, [_vm._v(_vm._s(_vm.showReportSource.title))])])]), _c('div', {
          staticClass: "d-flex align-items-start"
        }, [_c('b-button', {
          on: {
            "click": _vm.closeModalReport
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.showReportSource.body)
    }
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }